import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://7b2cb2dd18d82d0640a8b71fabefeaf6@o4506696647573504.ingest.sentry.io/4506696650194944',

  autoSessionTracking: false,
  sendClientReports: false,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
